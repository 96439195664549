import classNames from 'classnames'

export interface ModalFooterProps {
  divider?: boolean
  negativeText?: string
  positiveText?: string
  hiddenNegative?: boolean
  disabledNegative?: boolean
  disabledPositive?: boolean
  danger?: boolean
  onNegative?: () => void
  onPositive?: () => void
}

const ModalFooter = ({
  divider = true,
  negativeText = 'キャンセル',
  positiveText = 'OK',
  hiddenNegative = false,
  disabledNegative = false,
  disabledPositive = false,
  danger = false,
  onNegative,
  onPositive,
}: ModalFooterProps) => {
  return (
    <div
      className={classNames(
        'flex h-20 w-full items-center justify-end border-t border-base-200 p-2 pr-8',
        { 'border-t border-base-200': divider }
      )}
    >
      {!hiddenNegative && (
        <button
          type="button"
          disabled={disabledNegative}
          className="link-btn mr-8 underline"
          onClick={onNegative}
        >
          {negativeText}
        </button>
      )}
      <button
        type="submit"
        disabled={disabledPositive}
        className={classNames('btn text-white', {
          'btn-primary': !danger,
          'btn-error': danger,
        })}
        onClick={onPositive}
      >
        {positiveText}
      </button>
    </div>
  )
}

export default ModalFooter

//
