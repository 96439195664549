import classNames from 'classnames'

export interface ModalPaperProps {
  className?: string
  children?: React.ReactNode
}

const ModalPaper = ({ children, className }: ModalPaperProps) => {
  return (
    <div
      className={classNames('modal-box relative flex flex-col p-0', className)}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  )
}

export default ModalPaper
