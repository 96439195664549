import ModalFooter from '@/components/feedback/modal/modalFooter'
import ModalHeader from '@/components/feedback/modal/modalHeader'
import ModalOverlay from '@/components/feedback/modal/modalOverlay'
import ModalPaper from '@/components/feedback/modal/modalPaper'

export interface ModalProps {
  isOpen: boolean
  onClose?: () => void
  children?: React.ReactNode
  paperClassName?: string
  title?: string
  divider?: boolean
  negativeText?: string
  positiveText?: string
  hiddenNegative?: boolean
  disabledNegative?: boolean
  disabledPositive?: boolean
  danger?: boolean
  onNegative?: () => void
  onPositive?: () => void

  hiddenHeader?: boolean
  hiddenFooter?: boolean
}

const Modal = ({
  isOpen,
  onClose,
  children,
  paperClassName,
  title,
  divider,
  negativeText,
  positiveText,
  hiddenNegative,
  disabledNegative,
  disabledPositive,
  danger,
  onNegative,
  onPositive,

  hiddenHeader = false,
  hiddenFooter = false,
}: ModalProps) => (
  <ModalOverlay isOpen={isOpen} onClose={onClose}>
    <Modal.Paper className={paperClassName}>
      {!hiddenHeader && (
        <ModalHeader title={title} onClose={onClose} divider={divider} />
      )}
      <div className="w-full flex-1 overflow-y-auto">{children}</div>
      {!hiddenFooter && (
        <ModalFooter
          divider={divider}
          negativeText={negativeText}
          positiveText={positiveText}
          hiddenNegative={hiddenNegative}
          disabledNegative={disabledNegative}
          disabledPositive={disabledPositive}
          danger={danger}
          onNegative={onNegative}
          onPositive={onPositive}
        />
      )}
    </Modal.Paper>
  </ModalOverlay>
)

Modal.Overlay = ModalOverlay
Modal.Paper = ModalPaper
Modal.Header = ModalHeader
Modal.Footer = ModalFooter

export default Modal
