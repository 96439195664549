import React from 'react'
import AppHeader from '@/components/navigation/appHeader'
import Sidebar from '@/components/shared/sidebar'

interface BaseLayoutProps {
  children?: React.ReactNode
}

const BaseLayout = ({ children }: BaseLayoutProps) => (
  <div className="flex h-screen min-h-screen min-w-[1280px]">
    <Sidebar />
    <div className="h-full w-[calc(100%-200px)] flex-auto flex-col">
      <AppHeader />
      <main className="h-[calc(100%-64px)] w-full overflow-auto bg-base-200">
        {children}
      </main>
    </div>
  </div>
)

export default BaseLayout
