import React from 'react'
import PuffLoader from 'react-spinners/PuffLoader'
import { LoaderSizeProps } from 'react-spinners/helpers/props'
import { defaultThemeColor } from 'vidbase-utils/vidbase/palette'
import BlockRoot from './blockRoot'
import ScreenRoot from './screenRoot'

const Loader = (props: LoaderSizeProps) => {
  const { color = defaultThemeColor, ...rest } = props
  return <PuffLoader color={color} {...rest} />
}

Loader.BlockRoot = BlockRoot
Loader.ScreenRoot = ScreenRoot

export default Loader
