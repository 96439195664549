import useSWR from 'swr'
import { Portal } from 'vidbase-types/portal'
import { getPortals } from '@/repositories/portal'

// FIXME Do not need more this hook if dynamic routing is used and the id is included in the URL
const useGetPortalId = (workspaceId: string | undefined) => {
  const { data, error, mutate } = useSWR<Portal[], Error>(
    [`/workspaces/${workspaceId}/portals`],
    workspaceId ? () => getPortals(workspaceId) : null
  )
  const portalId = data?.[0]?.id
  return {
    portalId,
    error,
    mutate,
  }
}

export default useGetPortalId
