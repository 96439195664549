import { DocumentReference, Timestamp } from 'firebase/firestore'
import useSWR from 'swr'
import { Portal } from 'vidbase-types/portal'
import { getPortal } from '@/repositories/portal'

const useGetPortal = (
  workspaceId: string | undefined,
  portalId: string | undefined
) => {
  const { data, error, mutate } = useSWR<
    Portal<Timestamp, DocumentReference> | undefined,
    Error
  >(
    [`/workspaces/${workspaceId}/portals/${portalId}`],
    workspaceId && portalId ? () => getPortal(workspaceId, portalId) : null
  )
  return {
    portal: data,
    error,
    mutate,
  }
}

export default useGetPortal
