import { useRouter } from 'next/router'
import { useMemo } from 'react'
import Modal from '@/components/feedback/modal'
import useAuthUser from '@/hooks/auth/useAuthUser'
import useWorkspace from '@/hooks/workspace/useWorkspace'

const ignorePathnames = ['/settings?mfa_setting_open=true']

const MultiFactorRequiredGuard = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const router = useRouter()
  const [authUser] = useAuthUser()
  const [workspace] = useWorkspace()

  const isIgnorePathname = useMemo(() => {
    return ignorePathnames.includes(router.asPath)
  }, [router])

  const isExistMfaInfo = useMemo(() => {
    const reloadUserInfo = (authUser as any)?.reloadUserInfo
    if (!reloadUserInfo) {
      return false
    }

    const isExist =
      !!reloadUserInfo.mfaInfo && reloadUserInfo.mfaInfo.length > 0
    return isExist
  }, [authUser])

  if (!isIgnorePathname && workspace?.identity.mfaRequired && !isExistMfaInfo) {
    return (
      <>
        {children}
        <Modal
          isOpen={true}
          paperClassName="h-[360px]"
          title="2段階認証の設定"
          divider={true}
          positiveText="2段階認証を設定する"
          hiddenNegative={true}
          onPositive={() => router.push('/settings?mfa_setting_open=true')}
        >
          <div className="container mx-auto flex h-full flex-col justify-center">
            <p className="text-center text-base font-medium text-natural">
              このワークスペースでは2段階認証の設定が必要です
            </p>
          </div>
        </Modal>
      </>
    )
  }

  return <>{children}</>
}

export default MultiFactorRequiredGuard
