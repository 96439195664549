import classNames, { Argument } from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

export interface MenuItemProps {
  href: string
  icon: string
  title: string
}

const MenuItem = ({ href, icon, title }: MenuItemProps): JSX.Element => {
  const router = useRouter()

  const isActiveLink =
    href === router.pathname ||
    (href === '/contents' && router.pathname.startsWith('/contents/')) ||
    (href === '/portal' && router.pathname.startsWith('/portal/'))

  const menuClassname: Argument = {
    'bg-base-200 rounded-r-md': isActiveLink,
    'h-[48px] px-4 py-2 group hover:bg-base-200 hover:rounded-r-md cursor-pointer':
      true,
  }

  const iconClassname: Argument = {
    'text-primary opacity-100': isActiveLink,
    'text-natural opacity-60': !isActiveLink,
    'material-icons mr-2 group-hover:text-primary group-hover:opacity-100':
      true,
  }

  return (
    <Link href={href} legacyBehavior>
      <aside className={classNames(menuClassname)}>
        <span className="flex h-full w-full items-center">
          <span className={classNames(iconClassname)}>{icon}</span>
          <span className="text-sm">{title}</span>
        </span>
      </aside>
    </Link>
  )
}

export default MenuItem
