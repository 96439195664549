import * as Sentry from '@sentry/nextjs'
import { signOut } from 'firebase/auth'
import React from 'react'
import useMyWorkspaceUser from '@/hooks/workspaceUser/useMyWorkspaceUser'
import { logEvent } from '@/services/amplitude/analytics'
import { auth } from '@/services/firebase/client'
import { getIconName } from '@/services/user'

interface Props extends ContainerProps {
  logout: (e: React.MouseEvent<HTMLAnchorElement>) => Promise<void>
  iconName: string | undefined
}

interface ContainerProps {}

const Component: React.FC<Props> = ({ logout, iconName }) => (
  <div className="navbar h-[64px] justify-end border-b border-base-300 bg-base-200 px-6">
    <div className="mr-6 flex-none">
      <div
        data-tip="現在開発中です🙇‍♂️"
        className="tooltip tooltip-bottom tooltip-secondary cursor-not-allowed"
      >
        <span className="material-icons text-natural opacity-60">search</span>
      </div>
    </div>
    <div className="mr-6 flex-none">
      <div
        data-tip="現在開発中です🙇‍♂️"
        className="tooltip tooltip-bottom tooltip-secondary cursor-not-allowed"
      >
        <span className="material-icons text-natural opacity-60">
          notifications
        </span>
      </div>
    </div>
    <div className="mr-12 flex-none">
      <div
        data-tip="ご利用ガイド"
        className="tooltip tooltip-bottom tooltip-secondary"
      >
        <a
          href={
            'https://betamind.notion.site/Vidbase-29813dd51d884619b086e30d0b231298'
          }
          target={'_blank'}
          rel={'noreferrer noopener'}
        >
          <span className="material-icons text-natural opacity-60">help</span>
        </a>
      </div>
    </div>
    <div className="flex-none">
      <div className="dropdown-end dropdown">
        <div tabIndex={0} className="placeholder avatar cursor-pointer">
          <div className="h-12 w-12 rounded-full bg-primary text-white">
            <span>{iconName || ''}</span>
          </div>
        </div>
        <ul
          tabIndex={0}
          className="dropdown-content menu w-52 rounded-md border border-solid border-base-300 bg-white py-2 shadow-md"
        >
          <li>
            <a href="#" onClick={logout}>
              ログアウト
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
)

const AppHeader: React.FC<ContainerProps> = () => {
  const [me] = useMyWorkspaceUser()

  const logout = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    logEvent('click_sign_out')
    Sentry.configureScope((scope) => scope.setUser(null))
    await signOut(auth)
  }

  return <Component logout={logout} iconName={getIconName(me?.name ?? '')} />
}

export default AppHeader
