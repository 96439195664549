import { Base, getConverter } from '../document'

export interface Portal<T = any, U = any> extends Base<T, U> {
  workspaceId: string
  portalId: string
  title: string
  description: string
  ogpImageUrl: string
  customDomain: string
  trackingCode: string
}

export type PortalSettings = Omit<Portal, keyof Base>

export const portalConverter = getConverter<Portal>()
