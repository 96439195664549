import classNames from 'classnames'

export interface ModalOverlayProps {
  isOpen: boolean
  onClose?: () => void
  children?: React.ReactNode
}

const ModalOverlay = ({ isOpen, children, onClose }: ModalOverlayProps) => (
  <div
    className={classNames('modal', {
      'modal-open bg-base-content bg-opacity-30': isOpen,
    })}
    onClick={onClose}
  >
    {children}
  </div>
)

export default ModalOverlay
