import classNames from 'classnames'

export interface ModalHeaderProps {
  title?: string
  onClose?: () => void
  divider?: boolean
}

const ModalHeader = ({ title, onClose, divider = false }: ModalHeaderProps) => {
  return (
    <div
      className={classNames(
        'flex h-20 w-full items-center justify-between p-2 pl-8 pr-4',
        { 'border-b border-base-200': divider }
      )}
    >
      <h3 className="text-lg font-bold">{title}</h3>

      {onClose ? (
        <button
          type="button"
          onClick={onClose}
          className="btn btn-ghost btn-square"
        >
          <span className="material-icons cursor-pointer text-natural opacity-70">
            close
          </span>
        </button>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ModalHeader
