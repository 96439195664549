import {
  DocumentReference,
  doc,
  collection,
  Timestamp,
} from 'firebase/firestore'
import { Portal, portalConverter } from 'vidbase-types/portal'
import { db } from '@/services/firebase/client'
import crudGenerator from '@/services/firebase/client/firestore/crudGenerator'

export const getPortalsRef = (workspaceId: string) => {
  const ref = collection(db, 'workspaces', workspaceId, 'portals')
  return ref
}

export const getPortalRef = (workspaceId: string, portalId?: string) => {
  const collectionRef = getPortalsRef(workspaceId)
  return portalId ? doc(collectionRef, portalId) : doc(collectionRef)
}

export const {
  get: getPortal,
  getList: getPortals,
  add: addPortal,
  create: createPortal,
  partialUpdate: partialUpdatePortal,
  delete: deletePortal,
} = crudGenerator<
  Portal<Timestamp, DocumentReference>,
  [workspaceId: string],
  [workspaceId: string, portalId: string]
>(getPortalsRef, getPortalRef, portalConverter)
