import { useRouter } from 'next/router'
import { Role } from 'vidbase-types/workspaceUser'
import Loader from 'vidbase-ui/components/feedback/loader'
import MultiFactorRequiredGuard from '@/components/shared/auth/multiFactorRequiredGuard'
import useAuthUser from '@/hooks/auth/useAuthUser'
import useMyUser from '@/hooks/user/useMyUser'
import useWorkspace from '@/hooks/workspace/useWorkspace'
import useMyWorkspaceUser from '@/hooks/workspaceUser/useMyWorkspaceUser'

const homePaths: { [key in Role]: string } = {
  admin: '/',
  sales: '/contents',
}

interface AppGuardProps {
  children?: React.ReactNode
  enabledRoles?: Role[]
}

const AppGuard = ({
  children,
  enabledRoles = ['admin', 'sales'],
}: AppGuardProps) => {
  const [authUser, authUserLoading] = useAuthUser()
  const [rootMe, rootMeLoading] = useMyUser()
  const [me, meLoading] = useMyWorkspaceUser()
  const [workspace, workspaceLoading] = useWorkspace()

  const router = useRouter()

  if (authUserLoading) {
    return (
      <Loader.ScreenRoot>
        <Loader />
      </Loader.ScreenRoot>
    )
  }

  const isLoading = authUser && (rootMeLoading || meLoading || workspaceLoading)

  if (isLoading) {
    return (
      <Loader.ScreenRoot>
        <Loader />
      </Loader.ScreenRoot>
    )
  }

  if (!authUser || !rootMe || !me || !workspace) {
    router.push('/signIn')
    return <></>
  }

  if (!enabledRoles.includes(me.role)) {
    router.push(homePaths[me.role])
    return <></>
  }

  return <MultiFactorRequiredGuard>{children}</MultiFactorRequiredGuard>
}

export default AppGuard
