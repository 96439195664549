import { DocumentReference, Timestamp } from 'firebase/firestore'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { WorkspaceUser } from 'vidbase-types/workspaceUser'
import { authUserState } from '@/store/authUser'
import { workspaceUsersState } from '@/store/workspaceUsers'

const useMyWorkspaceUser = (): [
  WorkspaceUser<Timestamp, DocumentReference> | undefined,
  boolean,
  Error | undefined
] => {
  const {
    workspaceUsers,
    loading: workspaceUsersLoading,
    error: workspaceUsersError,
  } = useRecoilValue(workspaceUsersState)
  const {
    authUser,
    loading: authUserLoading,
    error: authUserError,
  } = useRecoilValue(authUserState)

  const workspaceUser = useMemo(() => {
    const hit = workspaceUsers.find((user) => user.id === authUser?.uid)
    return hit
  }, [authUser, workspaceUsers])

  const loading = useMemo(() => {
    return authUserLoading || workspaceUsersLoading
  }, [authUserLoading, workspaceUsersLoading])

  const error = useMemo(() => {
    return authUserError || workspaceUsersError
  }, [authUserError, workspaceUsersError])

  return [workspaceUser, loading, error]
}

export default useMyWorkspaceUser
