import { useRouter } from 'next/router'
import { Role } from 'vidbase-types/workspaceUser'
import Logo from '@/components/shared/logo'
import MenuItem, { MenuItemProps } from '@/components/shared/sidebar/menuItem'
import useGetPortal from '@/hooks/portal/useGetPortal'
import useGetPortalId from '@/hooks/portal/useGetPortalId'
import useWorkspace from '@/hooks/workspace/useWorkspace'
import useMyWorkspaceUser from '@/hooks/workspaceUser/useMyWorkspaceUser'

type MenuItemConfig = MenuItemProps & {
  allowedRoles: Role[]
}

const menuConfig: MenuItemConfig[] = [
  {
    icon: 'show_chart',
    href: '/',
    title: 'ダッシュボード',
    allowedRoles: ['admin'],
  },
  {
    icon: 'video_library',
    href: '/contents',
    title: '動画リスト',
    allowedRoles: ['admin', 'sales'],
  },
  {
    icon: 'live_tv',
    href: '/webinars',
    title: 'ウェビナー',
    allowedRoles: ['admin'],
  },
  {
    icon: 'web',
    href: '/portal',
    title: 'ポータル',
    allowedRoles: ['admin'],
  },
  {
    icon: 'settings_ethernet',
    href: '/settings/integrations',
    title: 'ツール連携',
    allowedRoles: ['admin'],
  },
  {
    icon: 'settings',
    href: '/settings',
    title: '設定',
    allowedRoles: ['admin', 'sales'],
  },
]

const Sidebar = () => {
  const router = useRouter()
  const [user] = useMyWorkspaceUser()
  const [workspace] = useWorkspace()
  const { portalId } = useGetPortalId(workspace?.id)
  const { portal } = useGetPortal(workspace?.id, portalId)

  if (!user) {
    return <></>
  }

  return (
    <nav className="w-[200px] bg-white">
      <div
        className="h-[64px] cursor-pointer border-b border-base-300"
        onClick={() => router.push('/')}
      >
        <div className="flex h-full items-center p-4">
          <Logo width={120} height={28.8} />
        </div>
      </div>
      <div className="py-4 pr-4">
        {menuConfig
          .filter(
            (itemConfig) =>
              itemConfig.allowedRoles.includes(user.role) &&
              !(itemConfig.href === '/portal' && !portal) // Hide portal menu if portal is not created
          )
          .map(({ allowedRoles, ...itemProps }) => (
            <MenuItem key={itemProps.title} {...itemProps} />
          ))}
      </div>
    </nav>
  )
}

export default Sidebar
